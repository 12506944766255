import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"
import color from "@material-ui/core/colors/amber"

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const StyleButton = styled.div`
  display: inline-block;
  border-radius: 4px;
  padding: 14px 20px;
  min-width: 64px;
  color: #333;
  border: ${(props) => (props.border ? `1.5px solid ${props.border}` : "#fff")};
  text-transform: none;
  outline: 0;
  background: #fff;
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  line-height: 1;
  letter-spacing: normal;
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  border-radius: 50px;
  align-items: center;
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  color: ${(props) => (props.color ? props.color : "#333")};
  margin: 5px 10px 5px 10px;
`

const Button = ({
  children,
  to,
  onClick,
  disabled,
  variant = "outlined",
  color,
  bg,
  border,
}) => (
  <StyledLink onClick={onClick} to={to} disabled={disabled}>
    <StyleButton color={color} bg={bg} border={border}>
      {" "}
      {children}
    </StyleButton>{" "}
  </StyledLink>
)

export default withSimpleErrorBoundary(Button)
