import { faUser as profileIcon } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { Fragment, useContext, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import LoginStateContext, {
  withLoginStateContext,
} from "../contexes/LoginStateContext"
import Profile from "../pages/profile"
import { getCachedUserDetails, signOut } from "../services/moocfi"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"
import Button from "./Button"
import LocalButton from "./LocalButton"
const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`

const LoginControls = (props) => {
  const context = useContext(LoginStateContext)
  const [data, setData] = useState({ name: "Loading...", showModal: false })

  const doSignOut = (e) => {
    e.preventDefault()
    signOut()
  }

  useEffect(() => {
    async function fetchUserDetails() {
      if (!context.loggedIn) {
        return
      }
      const details = await getCachedUserDetails()
      let name = `${details?.user_field?.first_name || ""} ${
        details?.user_field?.last_name || ""
      }`.trim()
      if (name === "") {
        name = details.email
      }
      setData({
        ...data,
        name,
      })
    }
    fetchUserDetails()
  }, [context])

  const handleClick = () => {
    setData({ ...data, showModal: !data.showModal })
  }

  return context.loggedIn ? (
    <Fragment>
      <LocalButton
        onClick={handleClick}
        aria-label="Profiiliin"
        to="/"
        bg="#33BF9F"
        color="#333"
      >
        <StyledIcon icon={profileIcon} aria-label="Profiili" />
        {data.name}
      </LocalButton>
      <LocalButton onClick={doSignOut} aria-label="Kirjaudu ulos" role="button">
        {props.t("logout")}
      </LocalButton>
      <Profile showModal={data.showModal} handleClick={handleClick} />
    </Fragment>
  ) : (
    <Fragment>
      <Button to="/sign-up" aria-label="Luo käyttäjä" role="link" border="#333">
        {props.t("newAccount")}
      </Button>
      <Button
        to="/sign-in"
        aria-label="Kirjaudu sisään"
        role="link"
        bg="#33BF9F"
        color="#333"
        border="#33BF9F"
      >
        {props.t("login")}
      </Button>
    </Fragment>
  )
}

export default withTranslation("common")(
  withSimpleErrorBoundary(withLoginStateContext(LoginControls)),
)
