import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const StyledButton = styled.button`
  display: inline-block;
  width: auto;
  border-radius: 4px;
  padding: 14px 24px;
  color: #333;
  border: none;
  text-transform: none;
  outline: 0;
  background: #fff;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  backface-visibility: hidden;
  letter-spacing: normal;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  cursor: pointer;

  :hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.15), 0 0 5px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
`
export const Primary = styled(StyledButton)`
  padding: 16px 34px;
  background: ${(props) => (props.bg ? props.bg : "#f1f1f1")};
  color: ${(props) => (props.color ? props.color : "#f1f1f1")};
  font-weight: 500;
  border-radius: 50px;
  font-family: "Josefin Sans", sans-serif;
`

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`

const StyleButton = styled.div`
  display: inline-block;
  border-radius: 4px;
  padding: 14px 20px;
  min-width: 64px;
  color: #333;
  border: ${(props) => (props.border ? `1.5px solid ${props.border}` : "#fff")};
  text-transform: none;
  outline: 0;
  background: #fff;
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  line-height: 1;
  letter-spacing: normal;
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  border-radius: 50px;
  align-items: center;
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  color: ${(props) => (props.color ? props.color : "#333")};
  margin: 5px 10px 5px 10px;
`

const Button = ({
  children,
  to,
  onClick,
  disabled,
  variant = "outlined",
  color,
  bg,
  border,
}) => (
  <StyledLink onClick={onClick} to={to} disabled={disabled}>
    <StyleButton color={color} bg={bg} border={border}>
      {" "}
      {children}
    </StyleButton>{" "}
  </StyledLink>
)

export default withSimpleErrorBoundary(Button)
